import Vue from 'vue'
import Landing from './MarketingHome.vue'
import axios from 'axios'
import Spinner from 'vue-simple-spinner'
import router from './router'
import Multiselect from 'vue-multiselect'
import VTooltip from 'v-tooltip'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/assets/css/tooltip.css'
import '@/assets/css/tailwind.css'
import * as Localized from '../../localizationConfig'
Vue.prototype.Localized = Localized.default

// import HoneybadgerVue from '@honeybadger-io/vue'
/* const config = {
  apiKey: '50ea79d3',
  environment: process.env.NODE_ENV,
  revision: 'MASTER'
}
if (process.env.NODE_ENV !== 'development') {
  Vue.use(HoneybadgerVue, config)

  Vue.$honeybadger.beforeNotify((notice) => {
    if (process.env.NODE_ENV === 'development') {
      // console.log(notice)
    }
  })
}

Vue.config.errorHandler = function (err, vm, info) {
  Vue.$honeybadger.notify(err)
} */

Vue.config.productionTip = false
Vue.prototype.axios = axios
Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0)
Vue.use(VTooltip, {
  defaultHideOnTargetClick: true
})
Vue.component('vue-simple-spinner', Spinner)
Vue.component('multiselect', Multiselect)

new Vue({
  router,
  render: h => h(Landing)
}).$mount('#app')
